/*******************************
    User Variable Overrides
*******************************/

/**
  Folio Library card styling
 */
.ui.cards {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-auto-rows: 1fr;

  // large desktop
  @media only screen and (max-width: 1500px) {
    grid-template-columns: repeat(4, 25%);
  }

  // tablet and mobile
  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 33.3333%);
  }

  // small tablet and mobile
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }
  // mobile
  @media only screen and (max-width: 320px) {
    grid-template-columns: repeat(1, 100%);
  }

  .ui.card {
    cursor: pointer;
    width: auto;

    .ui.checkbox {
      position: absolute;
      top: 7px;
      left: 5px;
      z-index: 50;
      pointer-events: none;
    }

    .image {
      height: 10vw;
      min-height: 100px;
      max-height: 180px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 0;
        object-fit: contain;
      }
    }

    .content {
      .header {
        .line-clamp(2);
        margin: 0 0 2px;
      }

      .meta {
        .type {
          display: block;
          height: 16px;
          .line-clamp(1);
        }
      }
    }

    .extra.content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 44px;
      border-top: none;
      padding: 0;

      span {
        cursor: pointer;
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        color: @grey;

        .star.icon {
          opacity: 1;
        }

        &.favorite {
          i.icon {
            color: @yellow;
          }
        }

        &:hover {
          i.icon:not(.yellow) {
            color: @greyHover;
          }
        }
      }
    }
  }
}
